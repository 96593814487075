<div>
    <div class="sub-header">
        <div class="main-container container">
            <h1 class="page-title pt-1">Account</h1>
        </div>
    </div>

    <div class="main-container container">
        <div class="mt-3"  *ngIf="loadingPymentReport || paymentReport.data.length">
            <div class="row">
                <div class="col-12 col-md-6">
                    <div class="card card-style3 p-4">
                        <div class="d-flex">
                            <div class="notifyimg bg-info">
                                <i class="fa fa-user"></i>
                            </div>
                            <div class="flex-fill d-flex align-items-center text-nowrap">
                                <div class="d-sm-flex w-100">
                                    <div class="flex-fill border-sm-end px-4 w-50">
                                        <div> Attributed Patients <div class="text-dark fw-bold fs-22 lh-sm"><a
                                                    href="javascript:void(0)" class="nav-link d-inline-block px-0">{{
                                                    paymentReport.summary.attributed }}</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex-fill px-4 mt-3 mt-sm-0 w-50">
                                        <div> Engaged Patients <div class="text-dark fw-bold fs-22 lh-sm">
                                                <span class="d-inline-block pt-1">{{ paymentReport.summary.engaged
                                                    }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex-fill px-4 mt-3 mt-sm-0 w-50">
                                        <div> Assessed Patients <div class="text-dark fw-bold fs-22 lh-sm">
                                                <span class="d-inline-block pt-1">{{ paymentReport.summary.assessed
                                                    }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="card card-style3 p-4">
                        <div class="d-flex">
                            <div class="notifyimg bg-info">
                                <i class="fa fa-home"></i>
                            </div>
                            <div class="flex-fill d-flex align-items-center text-nowrap">
                                <div class="d-sm-flex w-100">
                                    <div class="flex-fill border-sm-end px-4">
                                        <div> Assessed Patients<div class="text-dark fw-bold fs-22 lh-sm">
                                                <a href="javascript:void(0)" class="nav-link d-inline-block px-0">{{
                                                    paymentReport.summary.assessed }}</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex-fill px-4 mt-3 mt-sm-0">
                                        <div> Last Payment <div class="text-dark fw-bold fs-22 lh-sm">
                                                <span class="d-inline-block pt-1">{{ paymentReport.summary.lastPayment | currency:'USD'
                                                    }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header border-bottom title-submenu d-flex">
                    <h3 class="card-title flex-fill">Show last 12 months of payments</h3>
                </div>
                <div class="card-body pt-3">
                    <div class="text-center">
                        <app-combination-chart [chartData]="paymentReport"
                            *ngIf="paymentReport.data.length"></app-combination-chart>
                    </div>
                </div>
            </div>
            <div class="card mt-1 mb-5 p-2">
                <div class="table-responsive">
                    <table id="admissionTable"
                        class="table text-nowrap m-0 table-bordered table-striped sorting_disabled">
                        <thead class="table-head text-uppercase">
                            <tr class="text-secondary bg-gray-200">
                                <th class="bg-transparent border-bottom-0 fs-12">Reporting <div>Period
                                    </div>
                                </th>
                                <th class="bg-transparent border-bottom-0 fs-12 wp-20">Practice <div>Name
                                    </div>
                                </th>
                                <th class="bg-transparent border-bottom-0 fs-12">Attributed <div>Patients
                                    </div>
                                </th>
                                <th class="bg-transparent border-bottom-0 fs-12">Engaged <div>Patients</div></th>
                                <th class="bg-transparent border-bottom-0 fs-12">Assessed <div>Patients</div></th>
                                <th class="bg-transparent border-bottom-0 fs-12">PMPM <div>Amount</div>
                                </th>
                                <th class="bg-transparent border-bottom-0 fs-12">PMPM <div>Payment Amount
                                    </div>
                                </th>
                                <th class="bg-transparent border-bottom-0 fs-12">Incentive <div>at 80%
                                    </div>
                                </th>
                                <th class="bg-transparent border-bottom-0 fs-12">Incentive <div>at 100%
                                    </div>
                                </th>
                                <th class="bg-transparent border-bottom-0 fs-12">Report <div>Date</div>
                                </th>
                            </tr>
                        </thead>
                        <tbody class="table-body" *ngIf="loadingPymentReport && !paymentReport.data.length">
                            <tr *ngFor="let i of getIterations()">
                                <td><span class="skeleton-loader"></span></td>
                                <td><span class="skeleton-loader"></span></td>
                                <td><span class="skeleton-loader"></span></td>
                                <td><span class="skeleton-loader"></span></td>
                                <td><span class="skeleton-loader"></span></td>
                                <td><span class="skeleton-loader"></span></td>
                                <td><span class="skeleton-loader"></span></td>
                                <td><span class="skeleton-loader"></span></td>
                                <td><span class="skeleton-loader"></span></td>
                                <td><span class="skeleton-loader"></span></td>
                            </tr>
                        </tbody>
                        <tbody class="table-body" *ngIf="paymentReport.data.length">
                            <tr *ngFor="let data of paymentReport.data">
                                <td>{{ data.reportingPeriod }}</td>
                                <td>{{ data.practiceName }}</td>
                                <td>{{ data.attributedPatients }}</td>
                                <td>{{ data.engaged }}</td>
                                <td>{{ data.assessed }}</td>
                                <td>{{ data.pmpmAmount | currency:'USD' }}</td>
                                <td>{{ data.pmpmPaymentAmount | currency:'USD' }}</td>
                                <td>{{ data.incentiveAtEightyPercent.toFixed(2) | currency:'USD' }}</td>
                                <td>{{ data.incentiveAtHundredPercent.toFixed(2) | currency:'USD' }}</td>
                                <td>{{ data.reportDate }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <app-no-records-found *ngIf="!loadingPymentReport && !paymentReport.data.length"></app-no-records-found>
    </div>
</div>